import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../Components/Heading";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import img from "./lamination.jpg";

const detail = [
  {
    header: "Lamination",
    image: img,
  },
];
const Lamination = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />

      <Container className="page-container">
        <Row className="image-container-section mt-3 mb-3">
          <Col md={12} sm={12}>
            <div className="spacilities-content mobile-lamination-content mb-4">
              Wood finishing for your doors and windows can make your space
              beautiful but adding wood to window and door panes is impractical
              and expensive. Lamination can be an efficient solution that
              creates the same effect as wood finishing. Go ahead, make your
              doors and windows look neat, beautiful while easy-to-maintain.
            </div>
            <Heading text={"Key Features:"} />
            <hr className="divided-line" />
            <ul className="ps-5">
              <li> Suitable for all kinds of doors and windows.</li>
              <li>
                Maintains the classic look while employing the latest
                technology.
              </li>
              <li>
                Available in brown base profiles instead of white base profiles
                for both sides.
              </li>
              <li>
                Lamination film on Our profiles renders the appearance of
                mahogany and/or oak.
              </li>
              <li>
                Difficulty in maintaining pristine white laminates is countered
                by grey or cream colour options.
              </li>
              <li>
                Available in various shades, which are Mahogany, Rustic Oak,
                Golden Oak.
              </li>
              <li>
                One solution that comes with a combination of advantages, which
                include – aesthetic appeal of wood, durability, acoustic and
                thermal insulation, hassle-free to maintain and comes with
                weather-dust proofing characteristics of uPVC doors and windows.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Lamination;

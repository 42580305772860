// import Loader from "react-loader-spinner";
import {
  faAddressCard, faAdjust, faArchway, faArrowRightFromBracket, faAward, faBars, faBriefcase, faBuildingColumns, faBuildingUn, faCircleQuestion, faCogs, faContactBook, faCreditCard, faDoorOpen, faDrawPolygon, faFile,
  faFolderTree, faHandshake, faHome, faHomeAlt, faHospital, faHotel, faImages, faRulerCombined, faSchool, faSheetPlastic, faShield, faSliders, faSlidersH, faUniversalAccess, faUserSecret, faWater, faWindowMaximize
} from /*...*/ "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import Navbar from "reactjs-navbar";
import "reactjs-navbar/dist/index.css";
import logo from "./assets/fullLogo.png";
import logo1 from "./assets/logo.png";


class Header extends Component {
  state = {
    isLoading: false,
  };

  render() {
    return (
      <Navbar
        alt="Moira Building"
        logo={logo1}
        className={"pr-4 header-position"}
        isLoading={this.state.isLoading}
        menuItems={[
          {
            title: "Home",
            icon: faHome,
            isAuth: true,
            onClick: () => {
              window.location.href='/';
             },
          },
          {
            title: "About Us",
            icon: faAddressCard,
            isAuth: true,
            subItems: [
              {
                title: "Our Company",
                icon: faBuildingUn,
                isAuth: true,
                href: "/OurCompany",
                onClick: () => {
                  window.location.href='/OurCompany';
                 },
              },
              {
                title: "Gallery",
                icon: faImages,
                isAuth: true,
                onClick: () => {
                  window.location.href='/Gallery';
                 },
              },
            ],
          },
          {
            title: "Products",
            icon: faFile,
            isAuth: true,
            subItems: [
              {
                title: "Types",
                icon: faFolderTree,
                isAuth: true,
                subItems: [
                  {
                    title: "Sliding",
                    icon: faSliders,
                    isAuth: true,
                    subItems: [
                      {
                        title: "Windows",
                        icon: faWindowMaximize,
                        isAuth: true,
                        onClick: () => {
                          window.location.href='/Sliding-Windows';
                         },
                        
                      },
                      {
                        title: "Doors",
                        icon: faDoorOpen,
                        isAuth: true,
                        onClick: () => {
                          window.location.href='/Sliding-Doors';
                         },
                      },
                    ],
                  },
                  {
                    title: "Casement",
                    icon: faSheetPlastic,
                    isAuth: true,
                    subItems: [
                      {
                        title: "Windows",
                        icon: faWindowMaximize,
                        isAuth: true,
                        onClick: () => {
                          window.location.href='/Casement-Windows';
                         },
                      },
                      {
                        title: "Doors",
                        icon: faDoorOpen,
                        isAuth: true,
                        onClick: () => {
                          window.location.href='/Casement-Doors';
                         },
                      },
                    ],
                  },
                  {
                    title: "Specialities",
                    icon: faAdjust,
                    isAuth: true,
                    subItems: [
                      {
                        title: "Arched",
                        icon: faArchway,
                        isAuth: true,
                        onClick: () => {
                          window.location.href='/Arched';
                         },
                      },
                      {
                        title: "Combination",
                        icon: faRulerCombined,
                        isAuth: true,
                        onClick: () => {
                          window.location.href='/Combination';
                         },
                      },
                      {
                        title: "Lamination",
                        icon: faCreditCard,
                        isAuth: true,
                        onClick: () => {
                          window.location.href='/Lamination';
                         },
                      },
                      {
                        title: "Bay Window",
                        icon: faWindowMaximize,
                        isAuth: true,
                        onClick: () => {
                          window.location.href='/Bay-Window';
                         },
                      },
                    ],
                  },
                ],
              },
              {
                title: "Accessories",
                icon: faUniversalAccess,
                isAuth: true,
                onClick: () => {
                  window.location.href='/Accessories';
                 },
              },
            ],
          },
          {
            title: "Moira Advantage",
            icon: faShield,
            isAuth: true,
            subItems: [
              {
                title: "Why uPVC",
                icon: faCircleQuestion,
                isAuth: true,
                onClick: () => {
                  window.location.href='/why-moira';
                 },
              },
              {
                title: "Production",
                icon: faWater,
                isAuth: true,
                onClick: () => {
                  window.location.href='/Production';
                 },
              },
            ],
          },
          {
            title: "Technical",
            icon: faCogs,
            isAuth: true,
            subItems: [
              {
                title: "Solutions",
                icon: faHandshake,
                isAuth: true,
                subItems: [
                  {
                    title: "Hotels",
                    icon: faHotel,
                    isAuth: true,
                    onClick: () => {
                      window.location.href='/Hotels';
                     },
                  },
                  {
                    title: "Offices",
                    icon: faBriefcase,
                    isAuth: true,
                    onClick: () => {
                      window.location.href='/Offices';
                     },
                  },
                  {
                    title: "Villas",
                    icon: faHomeAlt,
                    isAuth: true,
                    onClick: () => {
                      window.location.href='/Villas';
                     },
                  },
                  {
                    title: "Apartments",
                    icon: faBuildingColumns,
                    isAuth: true,
                    onClick: () => {
                      window.location.href='/Apartments';
                     },
                  },
                  {
                    title: "Schools",
                    icon: faSchool,
                    isAuth: true,
                    onClick: () => {
                      window.location.href='/Schools';
                     },
                  },
                  {
                    title: "Hospitals",
                    icon: faHospital ,
                    isAuth: true,
                    onClick: () => {
                      window.location.href='/Hospitals';
                     },
                  },
                ]
              },
              {
                title: "Profile Drawings",
                icon: faDrawPolygon,
                isAuth: true,
                subItems: [
                    {
                      title: "Casement Series",
                      icon: faBars,
                      isAuth: true,
                      subItems: [
                        {
                          title: "1-60 Casement Series",
                          icon: faArrowRightFromBracket,
                          isAuth: true,
                          onClick: () => {
                            window.location.href='/Casement-Series-1-60';
                           },
                        },
                        {
                          title: "1-39 Casement Series",
                          icon: faArrowRightFromBracket,
                          isAuth: true,
                          onClick: () => {
                            window.location.href='/Casement-Series-1-39';
                           },
                        },
                      ]
                    },
                    {
                      title: "Sliding Series",
                      icon: faSlidersH,
                      isAuth: true,
                      subItems: [
                        {
                          title: "1-75 Sliding Series",
                          icon: faArrowRightFromBracket,
                          isAuth: true,
                          onClick: () => {
                            window.location.href='/Sliding-Series-1-75';
                           },
                        },
                        {
                          title: "1-64 Sliding Series",
                          icon: faArrowRightFromBracket,
                          isAuth: true,
                          onClick: () => {
                            window.location.href='/Sliding-Series-1-64';
                           },
                        },
                        {
                          title: "1-60 Sliding Series",
                          icon: faArrowRightFromBracket,
                          isAuth: true,
                          onClick: () => {
                            window.location.href='/Sliding-Series-1-60';
                           },
                        },
                        {
                          title: "1-50 Sliding Series",
                          icon: faArrowRightFromBracket,
                          isAuth: true,
                          onClick: () => {
                            window.location.href='/Sliding-Series-1-50';
                           },
                        },
                        {
                          title: "1-44 Sliding Series",
                          icon: faArrowRightFromBracket,
                          isAuth: true,
                          onClick: () => {
                            window.location.href='/Sliding-Series-1-44';
                           },
                        },
                      ]
                    },
                ]
              },
            ]
          },
          {
            title: "Contact Us",
            icon: faContactBook,
            isAuth: true,
            onClick: () => {
              window.location.href='/Contact-Us';
             },
          },
        ]}
      />
    );
  }
}
export default Header;

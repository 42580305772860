import React from "react";
import { Accordion, Container } from "react-bootstrap";
import Paragraph from "../../../../Components/base/Paragraph";
import Heading from "../../../../Components/Heading";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import img from "./door.jpg";
const detail = [
  {
    header: "Doors",
    image: img,
  },
];

const SDoors = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />
      <Container>
        <div className="p-2">
          <Paragraph
            text={
              "Flood your home with the beauty of the outside world With uPVC sliding doors. With a gentle push, our sliding doors glide open sideways, offering easy access to outdoor spaces.uPVC sliding doors are best suited for spaces with balconies or verandas. Available in a range of sizes, they can also be fitted with fly mesh depending on individual preferences."
            }
          />

          <h3>Here’s a look at our collection of sliding doors:</h3>
          <Heading text={"Type of Doors"} />
          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>2 Track uPVC Sliding Door</Accordion.Header>
                <Accordion.Body>
                  This quintessential uPVC door revolutionised the door
                  industry in India. Our track uPVC sliding doors are
                  easy to use, functional and cost-effective.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Easy to use</li>
                    <li>Cost efficient</li>
                    <li>Multiple size options</li>
                    <li>Aesthetically pleasing</li>
                    <li>Maintenance free</li>
                    <li>Provides 50% opening at any given point</li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span>10’ Height x 10’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2.5 Track uPVC Sliding Door
                </Accordion.Header>
                <Accordion.Body>
                  This quintessential uPVC door revolutionised the door
                  industry in India. MOIRA track uPVC sliding doors are
                  easy to use, functional and cost-effective.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Easy to use</li>
                    <li>Cost efficient</li>
                    <li>Multiple size options</li>
                    <li>Aesthetically pleasing</li>
                    <li>Maintenance free</li>
                    <li>Provides 50% opening at any given point</li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span>8’ Height x 8’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>3 Track uPVC Sliding Door</Accordion.Header>
                <Accordion.Body>
                  This uPVC sliding door has the same features as the classic
                  2 track sliding door with an added advantage of fly mesh
                  compatibility.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Easy to use</li>
                    <li>Maintenance free</li>
                    <li>Aesthetically pleasing</li>
                    <li>Multiple size options for larger doors</li>
                    <li>Fly mesh compatibility</li>
                    <li>Provides 66% opening at any given point</li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span>10’ Height x 10’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SDoors;

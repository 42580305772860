export const menu =[
  {
    title: "Home",
    onClick: () => {
      window.location.href='/';
     },
  },
  {
    title: "About Us",
    items: [
      {
        title: "Our Company",
        onClick: () => {
          window.location.href='/OurCompany';
         },
      },
      {
        title: "Gallery",
        onClick: () => {
          window.location.href='/Gallery';
         },
      },
    ],
  },
  {
    title: "Products",
    items: [
      {
        title: "Types",
        items: [
          {
            title: "Sliding",
            items: [
              {
                title: "Windows",
                onClick: () => {
                  window.location.href='/Sliding-Windows';
                 },
                
              },
              {
                title: "Doors",
                onClick: () => {
                  window.location.href='/Sliding-Doors';
                 },
              },
            ],
          },
          {
            title: "Casement",
            items: [
              {
                title: "Windows",
                onClick: () => {
                  window.location.href='/Casement-Windows';
                 },
              },
              {
                title: "Doors",
                onClick: () => {
                  window.location.href='/Casement-Doors';
                 },
              },
            ],
          },
          {
            title: "Specialities",
            items: [
              {
                title: "Arched",
                onClick: () => {
                  window.location.href='/Arched';
                 },
              },
              {
                title: "Combination",
                onClick: () => {
                  window.location.href='/Combination';
                 },
              },
              {
                title: "Lamination",
                onClick: () => {
                  window.location.href='/Lamination';
                 },
              },
              {
                title: "Bay Window",
                onClick: () => {
                  window.location.href='/Bay-Window';
                 },
              },
            ],
          },
        ],
      },
      {
        title: "Accessories",
        onClick: () => {
          window.location.href='/Accessories';
         },
      },
    ],
  },
  {
    title: "Moira Advantage",
    items: [
      {
        title: "Why uPVC",
        onClick: () => {
          window.location.href='/why-moira';
         },
      },
      {
        title: "Production",
        onClick: () => {
          window.location.href='/Production';
         },
      },
    ],
  },
  {
    title: "Technical",
    items: [
      {
        title: "Solutions",
        onClick: () => {
          window.location.href='/Solutions';
         },
      },
      {
        title: "Profile Drawings",
        items: [
            {
              title: "Casement Series",
              items: [
                {
                  title: "1-60 Casement Series",
                  onClick: () => {
                    window.location.href='/Casement-Series-1-60';
                   },
                },
                {
                  title: "1-39 Casement Series",
                  onClick: () => {
                    window.location.href='/Casement-Series-1-39';
                   },
                },
              ]
            },
            {
              title: "Sliding Series",
              items: [
                {
                  title: "1-75 Sliding Series",
                  onClick: () => {
                    window.location.href='/Sliding-Series-1-75';
                   },
                },
                {
                  title: "1-64 Sliding Series",
                  onClick: () => {
                    window.location.href='/Sliding-Series-1-64';
                   },
                },
                {
                  title: "1-60 Sliding Series",
                  onClick: () => {
                    window.location.href='/Sliding-Series-1-60';
                   },
                },
                {
                  title: "1-50 Sliding Series",
                  onClick: () => {
                    window.location.href='/Sliding-Series-1-50';
                   },
                },
                {
                  title: "1-44 Sliding Series",
                  onClick: () => {
                    window.location.href='/Sliding-Series-1-44';
                   },
                },
              ]
            },
        ]
      },
    ]
  },
  {
    title: "Contact Us",
    onClick: () => {
      window.location.href='/Contact-Us';
     },
  },
];

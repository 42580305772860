import React from 'react'
import MediaShow from "../../../../Components/base/MediaShow/MediaShow";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import img1 from "./assets/sl1.jpg";
import img2 from "./assets/sl2.jpg";
import img3 from "./assets/sl3.jpg";
import img4 from "./assets/sl4.jpg";
import img5 from "./assets/sl5.jpg";
import img from "./assets/slide6.jpg";
const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
];
const detail = [
  {
    header: " I - 44 SLIDING SERIES",
    image: img,
  },
];

const SlidingSFive = () => {
  return (
    <div>
      <Slides data={detail} />
      <MediaShow data={images} />
    </div>
  )
}

export default SlidingSFive;

import React from "react";
import { Col, Row } from "react-bootstrap";
import Heading from "../../../../Components/Heading";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import Combo1 from "./Assets/combi1.jpg";
import Combo2 from "./Assets/combi2.jpg";
import Combo3 from "./Assets/combi3.jpg";
import Combo4 from "./Assets/combi4.jpg";
import "./combination.css";
import img from "./combination.jpg";
const detail = [
  {
    header: "Combination",
    image: img,
  },
];

const Combination = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />

      <Row className="combination-section">
        <Col md={12} sm={12}>
          <div className="spacilities-content mb-4">
            If you don’t want to choose between casement and sliding windows,
            then we have a solution that gives you the best of both worlds –
            Combination Windows. Built by combining one or two fixed/single sash
            side hung casement door/window/double sash side hung casement
            door/window, top hung/two/three track multi-sash sliding windows, it
            makes your home complete.
          </div>
          <Heading
            text={
              "Here’s a look at our collection of Combination Doors and Windows:"
            }
          />
          <hr className="divided-line" />
          <div className="combination-images">
            <img src={Combo1} />
            <img src={Combo2} />
            <img src={Combo3} />
            <img src={Combo4} />
          </div>
          <Heading text={"Key Features:"} />
          <hr className="divided-line" />
          <ul className="ps-5">
            <li> Looks aesthetically grand and imposing.</li>
            <li>
              Integrates a strong and attractive unit of doors and windows.
            </li>
            <li>
              IWorks best for wide, tall and external spaces in your walls.
            </li>
            <li>
              Multiple kinds of windows grouped together to form one
              comprehensive unit.
            </li>
            <li>
              Gives you the freedom to choose various permutation and
              combination of windows.
            </li>
            <li>
              Costs more because of the extra reinforcing required to make it
              more stable at the coupled joints .
            </li>
            <li>
              Discards the need for window stoppers because friction hinges stay
              open at any angle in high-speed winds.
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Combination;

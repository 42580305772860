import React from "react";
import Card from "react-animated-3d-card";
import "./Sliding.css";
import img from "./assets/slideD.jpg";
import img1 from "./assets/slideM.jpg";

import CssButton from "../../Components/CssButton";
import { Fade } from "react-reveal";

const Sliding = () => {
  return (
    <section id="about">
      <div  className="about" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="about-body index">
          <Fade left duration={2000}>
            <div className="about-description">
              <h1 className="gradient">SLIDING</h1>
              <p style={{ color: "#000000" }}>
                Enjoy uninterrupted view of your surroundings with stunning uPVC
                sliding windows and doors from Moira Building Systems.
                <br />
              </p>
              <div className="d-flex row">
                {" "}
                <CssButton label="Windows" link="/Sliding-Windows" />
                <CssButton label="Doors" link="/Sliding-Doors" />
              </div>
            </div>
          </Fade>
          <Fade right duration={2000}>
            <div className="desktop">
              <Card
                className="test card-size1"
                style={{
                  backgroundImage: `url(${img1})`,
                  width: "450px",
                  height: "300px",
                  cursor: "pointer",
                }}
                onClick={() => console.log("Card clicked")}
              />
            </div>
            <div className="mobile">
              <Card
                className="test card-size1"
                style={{
                  backgroundImage: `url(${img})`,
                  width: "300px",
                  height: "209px",
                  cursor: "pointer",
                }}
                onClick={() => console.log("Card clicked")}
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Sliding;

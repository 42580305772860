import React from "react";
import MediaShow from "../../../../Components/base/MediaShow/MediaShow";
import img1 from "./assets/1.jpg";
import img2 from "./assets/2.jpg";
import img3 from "./assets/3.jpg";
import img4 from "./assets/4.jpg";
import img5 from "./assets/5.jpg";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import img from "./assets/img.jpg";
const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
];
const detail = [
  {
    header: " 1-39 Casement Series",
    image: img,
  },
];
const CasementSTwo = () => {
  return (
    <div>
      <Slides data={detail} darkText={true} />
      <MediaShow data={images} />
    </div>
  );
};

export default CasementSTwo;

import React from "react";
import MediaShow from "../../Components/base/MediaShow/MediaShow";
import PageTitle from "../../Components/base/PageTitle";

const Media = () => {
  return (
    <div>
      <PageTitle title="Moira Media" />
      <MediaShow />
    </div>
  );
};

export default Media;

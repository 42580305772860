import React from "react";
import dash from "./assets/dash.svg";
import eth from "./assets/eth.svg";
import img from "./assets/img.jpg";
import miota from "./assets/miota.svg";
import "./style.css";

const GetStarted = () => {
  return (
    <div id="home-container">
      <div id="inner">
        {/* <img src={logo} alt="Crypts logo" id="logo" /> */}
        <h1 className="heading text-black fw-bold">
          TRY OUR NEW WINDOW DESIGNER
        </h1>
        <p className="heading-paragraph serif">
         Moira Building Systems was set up in Bangalore in 2010 with a keen
          interest on satisfying the customer needs to provide quality doors and
          Windows to customers. Over the past 16 years we have set up our own
          dealer network with a capability to cater to all cities and villages
          across Karnataka and Pan India. On providing quality doors. We are the only authorised
          profile partners for VEKA GERMANY and we are also proud to be
          associated as technology partner for VEKA GERMANY a global leader in
          the uPVC industry. We have supplied more than 1 million sft
          of doors and windows, all of which go through stringent quality
          measures and checks.
        </p>
        <a href="/Contact-Us" className="cta cta--white ">
          Get Started
        </a>
      </div>
      <div id="illustration">
        <img src={dash} alt="dash img" id="dash" className="crypto-icons" />
        <img src={miota} alt="iota img" id="iota" className="crypto-icons" />
        <img src={eth} alt="eth img" id="eth" className="crypto-icons" />
        <img src={img} alt="monitor img" id="monitor" />
      </div>
    </div>
  );
};

export default GetStarted;

import React from "react";
import { Card, Container } from "react-bootstrap";
import { Banner } from "../../../Components/base/Banner/Banner";
import img from "./villas.jpg";
const Villas = () => {
  return (
    <div>
      {" "}
      <Banner image={img} title={"MOIRA"} description={"Villas"} />
      <Container>
        <Card>
          <Card.Body>
            Adorn your villa with beautiful French windows and doors from . Our
             uPVC Windows not only enhance the beauty of your villas, but also
            keep away dust and pollution. That’s not all,  uPVC Windows provide
            unobstructed view of the world, while protecting you from the
            harmful effects of the environment outside. Noise, strong winds,
            heat and rain don’t stand a chance!
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Villas;

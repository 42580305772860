import React from "react";
import { Card, Container } from "react-bootstrap";
import { Banner } from "../../../Components/base/Banner/Banner";
import img from "./schools.jpg";
const Schools = () => {
  return (
    <div>
      <Banner image={img} title={"MOIRA"} description={"Schools"} />
      <Container>
        <Card>
          <Card.Body>
            ‘No honking’ signs are not enough to reduce noise pollution
            surrounding educational institutions. The environment of a school or
            college should be conducive for learning. However, external noise
            from passing traffic or loud music and internal noise from another
            class or the playground can disrupt the flow of teaching, learning
            and classroom management. Wood or metal school windows do not block
            out noise as effectively as  uPVC Windows. uPVC school windows are
            completely soundproof and ensure a peaceful learning environment for
            children. uPVC school windows also keep away dust and pollution,
            providing a healthy, tranquil environment for school children.
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
export default Schools;

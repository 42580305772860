import React from "react";
import { Accordion, Container } from "react-bootstrap";
import Paragraph from "../../../../Components/base/Paragraph";
import Heading from "../../../../Components/Heading";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import img from "./door2.jpg";
const detail = [
  {
    header: "Doors",
    image: img,
  },
];

const CasementDoors = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />
      <Container>
        <div className="p-2">
          <Paragraph
            text={
              "Ever wondered how do doors get their ‘Push’ or ‘Pull’ stickers on them? Casement doors make it possible. The uPVC doors that can be opened inwards and/or outwards with the help of hinges fixed on a frame are called casement doors. We have 3 bespoke styles available in this category."
            }
          />

          <h3>Take a look at our collection of casement doors:</h3>
          <Heading text={"Type of Doors"} />
          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Standard</Accordion.Header>
                <Accordion.Body>
                  A standard casement door is best suited for uPVC door that
                  come with sound and thermal insulation. The doors open either
                  ways.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Are cost efficient.</li>
                    <li>Opens up to 50%</li>
                    <li>Is easy to use and maintain.</li>
                    <li>Is available in multiple size options.</li>
                    <li>Doesn’t come with a fly mesh provision.</li>
                    <li>
                      Provides privacy when used along with frosted glass.
                    </li>
                    <li>
                      Discards the need for window stoppers because friction
                      hinges stay open at any angle in high-speed winds.
                    </li>
                    <li>
                      Acts as a barrier against dust and pollution due to the
                      double sealing with TPV gaskets for enhanced thermal and
                      sound insulation.
                    </li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span> 4’ Height x 4’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Tilt and Slide</Accordion.Header>
                <Accordion.Body>
                  A Tilt and Slide casement door is a 2-sash sliding door where
                  one sash is fixed and the other one is sliding. If you want
                  wide openings and low width sills, then this one is for you.
                  Get the best of both worlds with this as it combines the
                  functionality of a sliding door and sound and heat insulation
                  of casement doors.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Are cost efficient.</li>
                    <li>Opens up to 50%.</li>
                    <li>Is easy to use and maintain.</li>
                    <li>Is available in multiple size options.</li>
                    <li>Doesn’t come with a fly mesh provision.</li>
                    <li>
                      Provides privacy when used along with frosted glass.
                    </li>
                    <li>
                      Discards the need for window stoppers because friction
                      hinges stay open at any angle in high-speed winds.
                    </li>
                    <li>
                      Acts as a barrier against dust and pollution due to the
                      double sealing with TPV gaskets for enhanced thermal and
                      sound insulation.
                    </li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span>4’ Height x 4’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Slide and Fold</Accordion.Header>
                <Accordion.Body>
                  Ever wondered how do banquet halls divide their space
                  according to the number of people? They have slide and fold
                  door that folds up and stretches as required. It works like an
                  assembly of 3+ sashes that are hinged together. If you want to
                  use it normally, you can use it as Ingress and Egress where
                  one sash works like a single casement door sash.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li> Doesn’t come with a fly mesh provision.</li>
                    <li>Looks aesthetically appealing and pleasant.</li>
                    <li> Eases cleaning for the outside surface of glass.</li>
                    <li>Is available in multiple sizes for larger doors.</li>
                    <li>Can be used for ventilation due to the tilt option.</li>
                    <li>
                      Secures completely as the hardware locks the sash from all
                      around.
                    </li>
                    <li>
                      Switch between tilt and turn options carefully, especially
                      when you close the doors.
                    </li>
                    <li>
                      Acts as a barrier against dust and pollution due to the
                      double sealing with TPV gaskets for enhanced thermal and
                      sound insulation.
                    </li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span> 6’ Height x 6’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CasementDoors;

import React from "react";
import uuidv4 from "uuid";
import GalleryShow from "../../Components/base/GalleryShow/GalleryShow";
import img1 from "./assets/1.jpg"
import img2 from "./assets/2.jpg"
import img3 from "./assets/3.jpg"
import img5 from "./assets/5.jpg"
import img6 from "./assets/6.jpg"
import img7 from "./assets/7.jpg"
import img8 from "./assets/8.jpg"
import img9 from "./assets/9.jpg"
import img10 from "./assets/10.jpg"
import img11 from "./assets/11.jpg"
import img12 from "./assets/12.jpg"
import img13 from "./assets/13.jpg"
import img14 from "./assets/14.jpg"
import img15 from "./assets/15.jpg"
import img16 from "./assets/16.jpg"
import img17 from "./assets/17.jpg"
import img18 from "./assets/18.jpg"
import img19 from "./assets/19.jpg"
import img20 from "./assets/20.jpg"
import img21 from "./assets/21.jpg"
import img22 from "./assets/22.jpg"
import img23 from "./assets/23.jpg"
import img24 from "./assets/24.jpg"
import img25 from "./assets/25.jpg"
import img26 from "./assets/26.jpg"
import img27 from "./assets/27.jpg"
import img28 from "./assets/28.jpg"
import img29 from "./assets/29.jpg"
import img30 from "./assets/30.jpg"
import img31 from "./assets/31.jpg"
import img32 from "./assets/32.jpg"
import img33 from "./assets/33.jpg"
import img34 from "./assets/34.jpg"
import img35 from "./assets/35.jpg"
import img36 from "./assets/36.jpg"
import img37 from "./assets/37.jpg"
import img38 from "./assets/38.jpg"
import img39 from "./assets/39.jpg"
import img40 from "./assets/40.jpg"
import img41 from "./assets/41.jpg"
import img42 from "./assets/42.jpg"
import img43 from "./assets/43.jpg"
import img45 from "./assets/45.jpg"
import img46 from "./assets/46.jpg"
import img47 from "./assets/47.jpg"
import img48 from "./assets/48.jpg"
import img49 from "./assets/49.jpg"
import img50 from "./assets/40.jpg"
import img51 from "./assets/51.jpg"
import img52 from "./assets/52.jpg"
import img53 from "./assets/53.jpg"
import img54 from "./assets/54.jpg"
import img55 from "./assets/55.jpg"
import img56 from "./assets/56.jpg"
import img57 from "./assets/57.jpg"
import img58 from "./assets/58.jpg"
import img59 from "./assets/59.jpg"
import img60 from "./assets/60.jpg"
import img61 from "./assets/61.jpg"
import img62 from "./assets/62.jpg"
import img63 from "./assets/63.jpg"
import img64 from "./assets/64.jpg"

const image = [
  {
    key: uuidv4(),
    content: <img src={img1} alt="1" />,
  },
  {
    key: uuidv4(),
    content: <img src={img2} alt="2" />,
  },
  {
    key: uuidv4(),
    content: <img src={img3} alt="3" />,
  },
  {
    key: uuidv4(),
    content: <img src={img5} alt="5" />,
  },
  {
    key: uuidv4(),
    content: <img src={img6} alt="6" />,
  },
  {
    key: uuidv4(),
    content: <img src={img7} alt="7" />,
  },
  {
    key: uuidv4(),
    content: <img src={img8} alt="8" />,
  },
  {
    key: uuidv4(),
    content: <img src={img9} alt="9" />,
  },
  {
    key: uuidv4(),
    content: <img src={img10} alt="10" />,
  },
  {
    key: uuidv4(),
    content: <img src={img11} alt="11" />,
  },
  {
    key: uuidv4(),
    content: <img src={img12} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img13} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img14} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img15} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img16} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img17} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img18} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img19} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img20} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img21} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img22} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img23} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img24} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img25} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img26} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img27} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img28} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img29} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img30} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img31} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img32} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img33} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img34} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img35} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img36} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img37} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img38} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img39} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img40} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img41} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img42} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img43} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img45} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img46} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img47} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img48} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img49} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img50} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img51} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img52} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img53} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img54} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img55} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img56} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img57} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img58} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img59} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img60} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img61} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img62} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img63} alt="12" />,
  },
  {
    key: uuidv4(),
    content: <img src={img64} alt="12" />,
  },
];
const Gallery = () => {
  return (
    <div className="pt-4" style={{ paddingBottom: "6rem" }}>
      <h2 className="font-weight-bold pt-3 pb-3">Gallery</h2>
      <GalleryShow images={image} />
    </div>
  );
};

export default Gallery;

import React from 'react'

const Heading = (props) => {
  return (
    <div>
        <h2  className='fw-bold text-primary'>{props.text}</h2>
    </div>
  )
}

export default Heading
import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

import img1 from "./assets/A1.jpg";
import img2 from "./assets/Hospital.jpg";
import img3 from "./assets/hotels.jpg";
import img4 from "./assets/office.jpg";
import img5 from "./assets/schools.jpg";
import img6 from "./assets/villas.jpg";

const Solutions = () => {
  return (
    <>
      <h3 className="text-center pt-5">Solutions</h3>
      <Row className="p-4">
        <Col md={4} className="pb-2">
          <Card>
            <img src={img1} alt="img" />
            <CardBody>
              <CardTitle className="fw-bold">Apartment</CardTitle>
              <h6>
                Bring elegance and class to your home with uPVC doors and
                windows. solutions are designed to tackle everyday problems like
                noise, dust, pollution and rainwater seepage, while enhancing
                the beauty of your home.
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} className="pb-2">
          <Card>
            <img src={img3} alt="img" />
            <CardBody>
              <CardTitle className="fw-bold">Hotel</CardTitle>
              <h6>
                For travellers and guests, a hotel is much more than a place to
                spend the night. It is an experience. Be it a resort, a luxury
                hotel or a modest B&B, a hotel offers its guests a place to
                relax, unwind and be pampered. uPVC Windows and doors are
                perfect for a hotel room as they ensure a peaceful environment,
                while enhancing the overall looks of the hotel’s interiors and
                exteriors.
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <img src={img2} alt="img" />
            <CardBody>
              <CardTitle className="fw-bold">Hospital</CardTitle>
              <h6>
                A hospital is a place of healing, where a pristine, sterile
                environment is of utmost importance. Although hospitals enforce
                strict hygiene policies, minor details like the doors and
                windows used are often overlooked.
              </h6>
            </CardBody>
          </Card>
        </Col>

        <Col md={4} className="pb-2">
          <Card>
            <img src={img4} alt="img" />
            <CardBody>
              <CardTitle className="fw-bold">Office</CardTitle>
              <h6>
                In a day we spend around 8 to 10 hours at the office, sometimes
                even longer. It has become a second home for many of us.
                Therefore, just like a home, an office needs a serene and
                healthy environment, as it is vital for motivation, performance
                and productivity.
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} className="pb-2">
          <Card>
            <img src={img6} alt="img" />
            <CardBody>
              <CardTitle className="fw-bold">Villas</CardTitle>
              <h6>
                Adorn your villa with beautiful French windows and doors from .
                Our uPVC Windows not only enhance the beauty of your villas, but
                also keep away dust and pollution. That’s not all, uPVC Windows
                provide unobstructed view of the world, while protecting you
                from the harmful effects of the environment outside. Noise,
                strong winds, heat and rain don’t stand a chance!
              </h6>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} className="pb-2">
          <Card>
            <img src={img5} alt="img" />
            <CardBody>
              <CardTitle className="fw-bold">Schools</CardTitle>
              <h6>
                ‘No honking’ signs are not enough to reduce noise pollution
                surrounding educational institutions. The environment of a
                school or college should be conducive for learning. However,
                external noise from passing traffic or loud music and internal
                noise from another class or the playground can disrupt the flow
                of teaching.
              </h6>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Solutions;

import React from "react";
import { Card, Container } from "react-bootstrap";
import { Banner } from "../../../Components/base/Banner/Banner";
import img from "./Hospital.jpg";
const Hospitals = () => {
  return (
    <div>
      <Banner image={img} title={"MOIRA"} description={"Hospital"} />
      <Container>
        <Card>
          <Card.Body>
            A hospital is a place of healing, where a pristine, sterile
            environment is of utmost importance. Although hospitals enforce
            strict hygiene policies, minor details like the doors and windows
            used are often overlooked. Dust, pollution, rainwater and other
            external disturbances can seep in through the hospital windows and
            doors. Conventional windows made from wood or metal cannot keep
            these issues away. A great way to keep dust, pollution, leakage and
            noise away is to install uPVC hospital windows.{" "}
             uPVC hospital windows seal
            off the disturbances without blocking the beautiful view, creating a
            peaceful environment that promotes healing.
            <h4 className="text-primary pt-4">
              Advantages of uPVC Hospital windows:
            </h4>
            <p> Low maintenance and easy to clean Available in large sizes,</p>
            <p>Available in large sizes, creating bright and airy rooms</p>
            <p>  Weather resistant with temperature control</p>
          
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Hospitals;

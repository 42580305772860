import React from "react";
import { Card, Container } from "react-bootstrap";
import { Banner } from "../../../Components/base/Banner/Banner";
import apartment from "./assets/A1.jpg";
const Apartments = () => {
  return (
    <div>
      <Banner image={apartment} title={"MOIRA"} description={"Apartments"} />
      <Container>
        <Card>
          <Card.Body>
            Bring elegance and class to your home with{" "}
             uPVC doors and windows.{" "}
             solutions are designed to
            tackle everyday problems like noise, dust, pollution and rainwater
            seepage, while enhancing the beauty of your home. They meet the
            varied requirements of your home. Be it the sliding and the casement
            windows for your living rooms or the kitchen windows and the
            ventilators with provisions for fly mesh, security grill and
            cleaning from outside, {" "}
            caters to the need both functionally and aesthetically.{" "}
              uPVC Windows and doors
            require very little maintenance as compared to their wooden and
            aluminium counterparts. They also provide thermal insulation,
            keeping you warm in the winter and cool in the summer.
             understands the requirements
            of builders and the needs of the end user. The products while being
            price competitive are also extremely functional and user friendly.
            The windows, being factory finished, can be installed at the final
            stages of the building construction with a small workforce, thereby
            causing minimal disturbance and damages to other works.{" "}
              uPVC Windows are designed with
            inbuilt flexibility where the builder can provide basic options and
            the end user can enhance the options based on their requirements
            such as fly mesh.  uPVC
            windows are stylish, sturdy, long lasting and offer cost effective
            options. So why opt for anything else.
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Apartments;

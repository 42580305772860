import React from "react";
import img1 from "./assets/casM.jpg";
import img from "./assets/casD.jpg";

import "./Casement.css";
import Card from "react-animated-3d-card";
import { Fade } from "react-reveal";
import CssButton from "../../Components/CssButton";
const Casement = () => {
  return (
    <section id="about">
      <div className="about" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="about-body index">
          <Fade left duration={2000}>
            <div className="desktop">
              <Card
                className="test"
                style={{
                  backgroundImage: `url(${img1})`,
                  width: "450px",
                  height: "300px",
                  cursor: "pointer",
                }}
                onClick={() => console.log("Card clicked")}
              />
            </div>
            <div className="mobile movetop">
              <Card
                className="test"
                style={{
                  backgroundImage: `url(${img})`,
                  width: "300px",
                  height: "209px",
                  cursor: "pointer",
                }}
                onClick={() => console.log("Card clicked")}
              />
            </div>
          </Fade>

          <div className="about-description">
            <Fade right duration={2000}>
              <h1 className="casement-heading">CASEMENT</h1>
              <p style={{ color: "#000000" }}>
                Get picture-perfect windows that flood your home with beauty of
                the outside world With uPVC casement windows and doors from
                Moira Building Systems
                <br />
              </p>
              <div className="d-flex row">
                {" "}
                <CssButton label="Windows" link="/Casement-Windows" />
                <CssButton label="Doors" link="/Casement-Doors" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Casement;

import React from "react";
import Img2 from "../../Components/base/GalleryList/assets/energy.jpeg";
import Img3 from "../../Components/base/GalleryList/assets/indoor.jpg";
import Img5 from "../../Components/base/GalleryList/assets/leadfree.png";
import Img1 from "../../Components/base/GalleryList/assets/long.jpeg";
import Img4 from "../../Components/base/GalleryList/assets/safty.jpg";
import Img6 from "../../Components/base/GalleryList/assets/weatherRoof.jpg";
import VerticalSlider from "../../Components/base/VerticalSlider/VerticalSlider";

const sliderData = [
  {
    paragraph:
      "Most uPVC tends to change its colour and property due to sustained exposure to UV radiation. The engineered blend used in  uPVC Windows is UV stabilised that gives it a long life.",
    heading: "LONG-LASTING STRENGTH",
    subHeading: "",
    photos: [{ Img1 }],
  },
  {
    paragraph:
      "The multi-chambered design of Our profiles prevents the transfer of heat inside your house. This makes your house energy efficient by reducing your air-conditioning cost significantly.",
    heading: "ENERGY-EFFICIENT",
    subHeading: "",
    photos: [{ Img2 }],
  },
  {
    heading: "HEALTHIER INDOOR AIR QUALITY",
    paragraph:
      "The usage of high quality wool pile along with dual layer sealing system in  uPVC Windows prevents dust and pollution from entering your home. This ensures the air inside your home is cleaner and healthier.",
    subHeading: "",
    photos: [{ Img3 }],
  },
  {
    heading: "SAFETY & SECURE",
    paragraph:
      "Every uPVC window is reinforced by a concealed GI section which is designed based on the wind pressure of that region, providing additional strength and stability. Making your home safe and secure.",
    subHeading: "",
    photos: [{ Img4 }],
  },
  {
    heading: "LEAD-FREE",
    paragraph:
      " uPVC Windows are lead-free in line with the global trend to eliminate the use of lead in the construction industry. So, you can be sure about the health of your family",
    subHeading: "",
    photos: [{ Img5 }],
  },
  {
    heading: "WEATHERPROOF",
    paragraph:
      "The TPV gasket in  uPVC Windows keeps rainwater and wind away from your home, while the impact modifier provides resistance to the window during extreme weather conditions. This makes a uPVC window almost invincible in any kind of weather.",
    subHeading: "",
    photos: [{ Img6 }],
  },
  {
    heading: "MULTI CHAMBERED",
    paragraph:
      "Impact strength and durability of the windows are ensured with a number of multi-chambered sections with wall thickness of 2.3 mm to 2.5mm. Accommodates a wide range of hardware offering flexibility to choose existing and upcoming hardware.",
    subHeading: "",
    photos: [{ Img6 }],
  },
  {
    heading: "FUSION WELDED JOINTS",
    paragraph:
      "Made of ethylene propylene dienemer, the weather seals ensure tight sealing and enhance air tight packing. Equipped with good weathering properties and resistance, they arrest noise and water leakage. Avoids air gap between slider sashes, prevents insect invasion & whistling effect.",
    subHeading: "",
    photos: [{ Img6 }],
  },
  {
    heading: "GLAZING SYSTEM",
    paragraph:
      "Glazing systems are air tight sealants with suitable space-bar, desiccant to absorb moisture and provide primary and secondary sealants. Single, double and triple glazing options are available as per the customer's needs, for arresting noise and energy loss.",
    subHeading: "",
    photos: [{ Img6 }],
  },
];
const Why = () => {
  return (
    <div>
      <VerticalSlider
        title={"Features that Stay Perfect for Decades"}
        data={sliderData}
      />
    </div>
  );
};

export default Why;

import React from "react";
import { Card, Container } from "react-bootstrap";
import { Banner } from "../../../Components/base/Banner/Banner";
import img from "./office.jpg";
const Offices = () => {
  return (
    <div>
      {" "}
      <Banner image={img} title={"MOIRA"} description={"Offices"} />
      <Container>
        <Card>
          <Card.Body>
            In a day we spend around 8 to 10 hours at the office, sometimes even
            longer. It has become a second home for many of us. Therefore, just
            like a home, an office needs a serene and healthy environment, as it
            is vital for motivation, performance and productivity.  uPVC Windows
            & doors address these concerns and help create a peaceful and
            comfortable environment at the workplace. They provide high thermal
            insulation blocking the external heat and cold, thereby also keeping
            the air-conditioning costs in check. They don’t just look great;
            they also block out noise (both external & internal), keep pollution
            away and prevent rainwater seepages. Unlike wood and aluminium, uPVC
            windows are easy to maintain and it do not rust, fade, warp or rot.{" "}
             uPVC Windows & doors offer enduring beauty and are also durable
            leaving your office interiors looking beautiful for years to come.
            Adequate safety and security provisions are possible depending on
            the specific requirements of the customer. From grills and toughened
            glasses to general and specific locking facilities- all options are
            possible with <span className="fw-bold"> MOIRA</span>. I-60 Casement
            series from <span className="fw-bold"> MOIRA</span> is the right
            solution for offices. Not just for external windows & doors, but
            also for internal partitions.
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Offices;

import React from "react";

const CssButton = (props) => {
  const { label, link, style, className } = props;
  return (
    <a href={link} className={`cssBtn b-1 ${className}`} style={style}>
      {label ? label : "Button"}
    </a>
  );
};

export default CssButton;

import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import emailjs from "emailjs-com";
import React, { useState } from "react";
import { AiOutlineCheckCircle, AiOutlineSend } from "react-icons/ai";
import { Col, Container, Row } from "reactstrap";
import isEmail from "validator/lib/isEmail";
import "./Contacts.css";
import { AddressMap } from "./map/Map";

function ContactUs() {
  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  // const { theme } = useContext(ThemeContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const useStyles = makeStyles((t) => ({
    input: {
      // backgroundColor: `${theme.secondary}`,
      // color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
      },
    },
    message: {
      backgroundColor: ``,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
      },
    },
    label: {
      fontFamily: "var(--primaryFont)",
      fontWeight: 600,
      fontSize: "0.9rem",
      padding: "0 5px",
      transform: "translate(25px,50%)",
      display: "inline-flex",
    },
    socialIcon: {
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "21px",
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    detailsIcon: {
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "23px",
      transition: "250ms ease-in-out",
      flexShrink: 0,
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    submitBtn: {
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.08)",
      },
    },
  }));

  const classes = useStyles();

  const handleContactForm = (e) => {
    e.preventDefault();

    if (name && email && message) {
      if (isEmail(email)) {
        // emailjs
        // .sendForm(
        //   "service_1ehh3hk",
        //   "template_g1pmfzl",
        //   e.target,
        //   "user_LrfHJvXxS2chK1rJOxk5n"
        // )
        // .then((result) => {
        //   console.log("success", result);
        //   setSuccess(true);
        //   setErrMsg("");
        //   setName("");
        //   setEmail("");
        //   setMessage("");
        //   setOpen(false);
        // });
      } else {
        setErrMsg("Invalid email");
        setOpen(true);
      }
    } else {
      setErrMsg("Enter all the fields");
      setOpen(true);
    }
  };

  return (
    <div className="contact-backg text-white">
      <Container>
        <Row className="d-flex align-items-center">
          <Col>
            <div>
              <h1 className="text-white">Contact Us</h1>
                <div className="contacts-form p-4">
                  <form onSubmit={handleContactForm}>
                    <div className="input-container">
                      <label htmlFor="Name" className={"label-class"}>
                        Name
                      </label>
                      <input
                        placeholder="Enter Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        name="Name"
                        className={`form-input ${classes.input}`}
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="Email" className={"label-class"}>
                        Email
                      </label>
                      <input
                        placeholder="Enter Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        name="Email"
                        className={`form-input ${classes.input}`}
                      />
                    </div>
                    <div className="input-container">
                      <label htmlFor="Message" className={"label-class"}>
                        Message
                      </label>
                      <textarea
                        placeholder="Type your message...."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        type="text"
                        name="Message"
                        className={`form-message ${classes.message}`}
                      />
                    </div>

                    <div className="submit-btn">
                      <button type="submit" className={classes.submitBtn}>
                        <p className="pt-3">{!success ? "Send" : "Sent"}</p>
                        <div className="submit-icon">
                          <AiOutlineSend
                            className="send-icon"
                            style={{
                              animation: !success
                                ? "initial"
                                : "fly 0.8s linear both",
                              position: success ? "absolute" : "initial",
                            }}
                          />
                          <AiOutlineCheckCircle
                            className="success-icon"
                            style={{
                              display: !success ? "none" : "inline-flex",
                              opacity: !success ? "0" : "1",
                            }}
                          />
                        </div>
                      </button>
                    </div>
                  </form>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <SnackbarContent
                      action={
                        <React.Fragment>
                          <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </React.Fragment>
                      }
                      style={{
                        // backgroundColor: theme.primary,
                        // color: theme.secondary,
                        fontFamily: "var(--primaryFont)",
                      }}
                      message={errMsg}
                    />
                  </Snackbar>
                </div>
            </div>
          </Col>
          <Col>
            <AddressMap />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactUs;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import LayoutWithSlide from "../../Components/base/LayoutWithSlide";
import Slides from "../../Components/reactCarousel/ReactCarousel";

//Images
import img2 from "./assets/1.jpg";
import img3 from "./assets/2.jpg";
import img4 from "./assets/3.jpg";
import img1 from "./assets/img.jpg";

//Certificate
import cer1 from "./assets/c1.jpg";
import cer2 from "./assets/c2.jpg";
import cer3 from "./assets/c3.jpg";
import cer4 from "./assets/c3.jpg";
import cer5 from "./assets/c5.jpeg";
import cer6 from "./assets/c6.jpg";
import cer7 from "./assets/c7.jpg";

const detail = [
  {
    header: "Our Company",
    image: img1,
  },
];
const OurCompany = () => {
  return (
    <div>
      <Slides hide data={detail} />
      <LayoutWithSlide
        img1={img2}
        img2={img3}
        img3={img4}
        text1="Our high performance products add an architectural dimension to the home and keep out street noise, dust and pollution, rain and wind. Our solutions outlast buildings. "
        text2="Unmatched product quality and industry knowledge to support you in making the right decisions for your projects"
        text3="We consistently evolve and develop our offering to remain at the top of our industry and lead the path towards innovation."
      />
      <Container>
        <Row className="about-description">
          <Col md={12} sm={12}>
            <Fade left duration={2000}>
              <p className="our-company-description fs-5">
                <span className="fw-bold">Moira Building Systems</span> is the
                renowned company in the industry that has started its journey in
                the year 2010 at Bengaluru in Karnataka, India. We are basically
                the manufacturer and supplier of a wide range of products, which
                includes uPVC Windows, Top Hung Windows, Laminated Glass
                Windows, Bay Windows, uPVC Doors and Fixed Ventilation Louvres.
                We also providing Window Fabrication Services. These products
                are produced by our skilled and talented professionals using
                best part of their knowledge, high grade material and ultra
                modern techniques. Also, these products are highly recommended
                by customers and experts of the industry for their high
                reliability, durable finishing, availability in various sizes
                and designs, attractive look, superior quality and market
                leading prices. Apart from this, we also provide highly reliable
                and effective Window Fabrication Services to our clients as per
                the details and budget provided by them.
              </p>
            </Fade>
          </Col>
          <Col md={12} sm={12}>
            <Fade right duration={2000}>
              <p className="fs-5">
                We are the quality conscious company and for maintaining this
                position in the industry, we have been following set industrial
                quality norms and parameters throughout the process. Also, we
                have quality controlling department that is supervised by
                quality inspectors and equipped with technically advanced
                quality testing machines. With the help of this department, we
                are able to provide best quality product and services to our
                valuable customers. Besides this, client satisfaction is our
                prime objective. We take care of them and also improve our
                products and policies according to their feedback and
                suggestions. Our company has been maintaining a healthy
                relationship with its customers since the time of our
                establishment.We have achieved many targets with ease and
                perfection. Also, we have successfully carved a niche in this
                competitive market within a very short duration of time. In
                addition, our company is the best place for those who want to
                purchase above listed products in bulk and want effective
                services.
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
      <Row className="pb-5">
        <Col className="pb-3" lg={3}>
          <img
            style={{ width: "250px", height: "250px" }}
            src={cer1}
            alt="img"
          />
        </Col>
        <Col className="pb-3" lg={3}>
          <img
            style={{ width: "250px", height: "250px" }}
            src={cer2}
            alt="img"
          />
        </Col>
        <Col className="pb-3" lg={3}>
          <img
            style={{ width: "250px", height: "250px" }}
            src={cer3}
            alt="img"
          />
        </Col>
        <Col className="pb-3" lg={3}>
          <img
            style={{ width: "250px", height: "250px" }}
            src={cer4}
            alt="img"
          />
        </Col>
        <Col className="pb-3" lg={4}>
          <img
            style={{ width: "250px", height: "250px" }}
            src={cer5}
            alt="img"
          />
        </Col>
        <Col className="pb-3" lg={4}>
          <img
            style={{ width: "250px", height: "250px" }}
            src={cer6}
            alt="img"
          />
        </Col>
        <Col className="pb-3" lg={4}>
          <img
            style={{ width: "250px", height: "250px" }}
            src={cer7}
            alt="img"
          />
        </Col>
      </Row>
    </div>
  );
};

export default OurCompany;

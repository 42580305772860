import React from "react";
import { Accordion, Container, Nav } from "react-bootstrap";
import Paragraph from "../../../../Components/base/Paragraph";
import Heading from "../../../../Components/Heading";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import img from "./window.jpg";
const detail = [
  {
    header: "Windows",
    image: img,
  },
];

const CasementWindows = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />
      <Container>
        <div className="p-2">
          <Paragraph
            text={
              "Open up to the outside world with style. Let the windows of your home make the outside world elegant for you. At MOIRA, we make casement windows that are safe, suave and stylish. Take a look at the range we offer and choose the one that suits your style."
            }
          />

          <h3>Take a look at our collection of casement windows:</h3>
          <Heading text={"Type of Windows"} />
          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Standard</Accordion.Header>
                <Accordion.Body>
                  TMake your ventilation vents stylish and sturdy. The top hung
                  variant of our casement windows works best for bathrooms and
                  kitchens. They enhance air circulation and look good as the
                  shutters are hung from top using friction hinges.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Are cost efficient.</li>
                    <li>Opens up to 50%</li>
                    <li>Is easy to use and maintain.</li>
                    <li>Is available in multiple size options.</li>
                    <li>Doesn’t come with a fly mesh provision.</li>
                    <li>
                      Provides privacy when used along with frosted glass.
                    </li>
                    <li>
                      Discards the need for window stoppers because friction
                      hinges stay open at any angle in high-speed winds.
                    </li>
                    <li>
                      Acts as a barrier against dust and pollution due to the
                      double sealing with TPV gaskets for enhanced thermal and
                      sound insulation.
                    </li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span> 4’ Height x 4’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Top hung</Accordion.Header>
                <Accordion.Body>
                  Make your ventilation vents stylish and sturdy. The top hung
                  variant of our casement windows works best for bathrooms and
                  kitchens. They enhance air circulation and look good as the
                  shutters are hung from top using friction hinges.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Are cost efficient.</li>
                    <li>Opens up to 50%.</li>
                    <li>Is easy to use and maintain.</li>
                    <li>Is available in multiple size options.</li>
                    <li>Doesn’t come with a fly mesh provision.</li>
                    <li>
                      Provides privacy when used along with frosted glass.
                    </li>
                    <li>
                      Discards the need for window stoppers because friction
                      hinges stay open at any angle in high-speed winds.
                    </li>
                    <li>
                      Acts as a barrier against dust and pollution due to the
                      double sealing with TPV gaskets for enhanced thermal and
                      sound insulation.
                    </li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span>4’ Height x 4’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Tilt and Turn</Accordion.Header>
                <Accordion.Body>
                  This casement window works 2-ways. It acts in the top hung and
                  side hung window that opens both ways – inwards and outwards.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li> Doesn’t come with a fly mesh provision.</li>
                    <li>Looks aesthetically appealing and pleasant.</li>
                    <li> Eases cleaning for the outside surface of glass.</li>
                    <li>Is available in multiple sizes for larger windows.</li>
                    <li>Can be used for ventilation due to the tilt option.</li>
                    <li>
                      Secures completely as the hardware locks the sash from all
                      around.
                    </li>
                    <li>
                      Switch between tilt and turn options carefully, especially
                      when you close the windows.
                    </li>
                    <li>
                      Acts as a barrier against dust and pollution due to the
                      double sealing with TPV gaskets for enhanced thermal and
                      sound insulation.
                    </li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span> 6’ Height x 6’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Casement Window with Grill and Mesh
                </Accordion.Header>
                <Accordion.Body>
                  The ultimate in safety against insects and anti-social
                  elements, the casement windows with grill and mesh are the
                  perfect uPVC casement window solution. While the window sash
                  opens outwards, the inward opening sashes with the insect
                  screen. This is best suited for row houses, villas, bungalows,
                  ground floor apartments and such.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Is easy to use and maintain.</li>
                    <li> Is available in multiple sizes.</li>
                    <li> Comes with limited design options.</li>
                    <li> Looks majestic and aesthetically pleasant.</li>
                    <li>
                      Allows more air to enter the window compared to a sliding.
                    </li>
                    <li>
                      Locks at several points across the window for security and
                      better sealing.
                    </li>
                    <li>
                      Assures security and safety as an integrated grill is
                      screwed to the window frame.
                    </li>
                    <li>
                      Protects you against the attack of insects and mosquitoes,
                      even when the windows are open.
                    </li>
                    <li>
                      Discards the need for window stoppers because friction
                      hinges stay open at any angle in high-speed winds.
                    </li>
                    <li>
                      Acts as a barrier against dust and pollution due to the
                      double sealing with TPV gaskets for enhanced thermal and
                      sound insulation.
                    </li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span> 6’ Height x 6’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CasementWindows;

import React, { useState } from "react";
import DrawerComponent from "./Drawer";
import ToolbarComponent from "./Toolbar";

const NewNavbar = ({ scroll }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(false);
  };
  const openDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <ToolbarComponent openDrawerHandler={openDrawer} scroll={scroll} />
      <DrawerComponent open={isDrawerOpen} toggleDrawerHandler={toggleDrawer} />
    </div>
  );
};

export default NewNavbar;

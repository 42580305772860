import React from "react";
import './Accessories.scss'
import { Col, Container, Row } from "react-bootstrap";
import Slides from "../../Components/reactCarousel/ReactCarousel";
import img from "./acc.jpeg";
import Accessories1 from "./accesories1-1.jpg"
import Accessories2 from "./accesories2.jpg"
import Accessories3 from "./accesories3.jpg"
import Accessories4 from "./accesories4.jpg"
import Accessories5 from "./accesories5.jpg"
import Accessories6 from "./accesories6.jpg"

const detail = [
  {
    header: "Accessories",
    image: img,
  },
];

const Accessories = () => {
  return (
    <div>
      <Slides data={detail} />{" "}
      <Container>
        <h3>Presenting Moira Building Systems Range Of Accessories</h3>
        <div className="spacilities-content mb-4 p-3">
          As the leader in uPVC doors and windows, we at Moira Building Systems are always
          passionate about offering you the best solution in doors and windows.
          That’s why we are delighted to present our all-new range of
          accessories that, like our profiles, are perfectly suited for Indian
          conditions.Their versatile range allows you to use them in any window
          design you need at unbelievably affordable prices.
        </div>
        <Row className="image-container-section mt-3 mb-3">
            <Col md={4} sm={12}>
                <img src={Accessories1} className="accessories-img"/>
                <div className="accessories">ESPAGNOLETTES & STRIKERS</div>
            </Col>
            <Col md={4} sm={12}>
                <img src={Accessories2} className="accessories-img"/>
                <div className="accessories">ESPAGNOLETTES & STRIKERS</div>
            </Col>
            <Col md={4} sm={12}>
                <img src={Accessories3} className="accessories-img"/>
                <div className="accessories">ESPAGNOLETTES & STRIKERS</div>
            </Col>
        </Row>
        <Row className="image-container-section mt-3 mb-3">
            <Col md={4} sm={12}>
                <img src={Accessories4} className="accessories-img"/>
                <div className="accessories">ESPAGNOLETTES & STRIKERS</div>
            </Col>
            <Col md={4} sm={12}>
                <img src={Accessories5} className="accessories-img"/>
                <div className="accessories">ESPAGNOLETTES & STRIKERS</div>
            </Col>
            <Col md={4} sm={12}>
                <img src={Accessories6} className="accessories-img"/>
                <div className="accessories">ESPAGNOLETTES & STRIKERS</div>
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Accessories;

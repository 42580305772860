import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../Components/Heading";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import './Arched.css';
import img from "./arched.jpg";
const detail = [
  {
    header: "Arched",
    image: img,
  },
];
const Arched = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />

      <Container className="page-container">
        <Row className="image-container-section mt-3 mb-3">
          <Col md={12} sm={12}>
            <div className="spacilities-content mobile-arched-content mb-4">
            Invite the old-world charm of an arched window and take the aesthetic value of your home to another level. An arched window is rectangular at the base and semi-circled or arched on the top, the ones that you see in artworks. Break the monotony of rectangular windows without compromising on the inward allowance of air and sunshine.
            </div> 
            <Heading text={"Key Features:"} />
            <hr className="divided-line"/>  
            <ul className="ps-5 arched">
              <li>Are cost efficient.</li>
              <li>Opens up to 50%</li>
              <li>Is easy to use and maintain.</li>
              <li>Is available in multiple size options.</li>
              <li>Doesn’t come with a fly mesh provision.</li>
              <li>Provides privacy when used along with frosted glass.</li>
              <li>
                Discards the need for window stoppers because friction hinges
                stay open at any angle in high-speed winds.
              </li>
              <li>
                Acts as a barrier against dust and pollution due to the double
                sealing with TPV gaskets for enhanced thermal and sound
                insulation.
              </li>
            </ul>
          </Col>

          {/* <Col md={4} sm={12} className="image-section">
            <img src={img} alt="product-image" className="product-image" />
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Arched;

import React, { useState } from "react";
// import { FooterSection } from './styled';
import Moira from "./svg/fullLogo.png";
import { Button, Col, Row } from "react-bootstrap";
import "./footer.css";

import Email from "./svg/email-icon.svg";
import Call from "./svg/call-icon.svg";
import EmailIcon from "./svg/email.svg";
import {
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
const Footer = () => {
  const [email, setEmail] = useState("");
  return (
    <div className="footer-section">
      <Row className="row-control">
        <Col md={3} sm={12} className="footer-content">
          <img src={Moira} className="moira-white-logo" />
          <span className="content moira-brief">
            Moira Building System is specialized in uPVC windows and doors.
          </span>
        </Col>

        <div className="contact-section mobile">
          <span className="contact-header">Have a question?</span>
          <div className="contact-details-section">
            <a href="tel:9606492202" className="contact-details">
              <img src={Call} />
              <div className="contact-content">
                <span className="contact-name">Office:</span>
                <span className="contact-number">9606492201</span>
              </div>
            </a>
            <a href="tel:9606492202" className="contact-details">
              <img src={Call} />
              <div className="contact-content">
                <span className="contact-name">Design/Technical:</span>
                <span className="contact-number">9606492202</span>
              </div>
            </a>
          </div>
          <div className="pt-3">
          <a href="mailto:moirabuilds@gmail.com" className="contact-details">
            <img src={Email} />
            <div className="contact-content">
              <span className="contact-name">Drop an email at:</span>
              <span className="contact-number">moirabuilds@gmail.com</span>
            </div>
          </a>
          </div>
        </div>

        <Col md={3} sm={6} className="footer-content web-section">
          <span className="content-header">Relevant Links</span>
          <span className="content">
            <span
              className="relevant-links"
              onClick={(e) => window.open("/OurCompany")}
            >
              Our Company
            </span>
            <span
              className="relevant-links"
              onClick={(e) => window.open("/why-moira")}
            >
              Why uPVC
            </span>
            <span
              className="relevant-links"
              onClick={(e) => window.open("/Gallery")}
            >
              Gallery
            </span>
            <span
              className="relevant-links"
              onClick={(e) => window.open("/Contact-Us")}
            >
              Contact US
            </span>
          </span>
        </Col>
        <Col md={3} sm={6} className="footer-content web-section">
          <span className="content-header">Social Media</span>
          <span className="content">
            <span
              className="social-media-section"
              onClick={(e) => window.open("https://www.instagram.com/")}
            >
               <FaInstagram aria-label="Instagram" />
              <span>Instagram</span>
            </span>

            <span
              className="social-media-section"
              onClick={(e) => window.open("https://twitter.com/")}
            >
              <FaTwitter aria-label="Twitter" />
              <span>Twitter</span>
            </span>

            <span
              className="social-media-section"
              onClick={(e) => window.open("https://www.linkedIn.com/")}
            >
              <FaLinkedinIn aria-label="LinkedIn" />
              <span>LinkedIn</span>
            </span>

            <span
              className="social-media-section"
              onClick={(e) => window.open("https://www.youtube.com/")}
            >
             <FaYoutube aria-label="YouTube" />
              <span>Youtube</span>
            </span>
          </span>
        </Col>

        <div className="mobile-section">
          <div className="footer-content relevant-link">
            <span className="content-header">Relevant Links</span>
            <span className="content link-section">
              <span
                className="relevant-links"
                onClick={(e) => window.open("/OurCompany")}
              >
                Our Company
              </span>
              <span
                className="relevant-links"
                onClick={(e) => window.open("/why-moira")}
              >
                Why uPVC
              </span>
              <span
                className="relevant-links"
                onClick={(e) => window.open("/Gallery")}
              >
                Gallery
              </span>
              <span
                className="relevant-links"
                onClick={(e) => window.open("/Contact-Us")}
              >
                Contact US
              </span>
            </span>
          </div>
          <div className="footer-content relevant-link">
            <span className="content-header">Social Media</span>
            <span className="content link-section">
              <span
                className="social-media-section"
                onClick={(e) => window.open("https://www.instagram.com/")}
              >
                 <FaInstagram aria-label="Instagram" />
                <span>Instagram</span>
              </span>

              <span
                className="social-media-section"
                onClick={(e) => window.open("https://twitter.com/")}
              >
                 <FaTwitter aria-label="Twitter" />
                <span>Twitter</span>
              </span>

              <span
                className="social-media-section"
                onClick={(e) => window.open("https://www.linkedIn.com/")}
              >
               <FaLinkedinIn aria-label="LinkedIn" />
                <span>LinkedIn</span>
              </span>

              <span
                className="social-media-section"
                onClick={(e) => window.open("https://www.youtube.com/")}
              >
                 <FaYoutube aria-label="YouTube" />
                <span>Youtube</span>
              </span>
            </span>
          </div>
        </div>
        <Col md={3} sm={12} className="footer-content">
          <span className="content-header">Address</span>
          <span className="content qoruz-brief">
            MOIRA BUILDING SYSTEMS,
            <br />
            NO 91, 187/1, Kudlu Main Rd,
            <br />
            Srinivasa Nagar, AECS Layout, Singasandra,
            <br />
            Bengaluru, Karnataka
          </span>
          <div className="email-section">
            <input
              type="email"
              placeholder="Enter your email"
              name="email"
              className="email-field"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {!email && <img src={EmailIcon} className="email-icon" />}
            <Button className="email-submit">
              <span className="btn-text">Submit</span>
            </Button>
          </div>
        </Col>
      </Row>
      <div className="contact-section web-contact">
        <span className="contact-header">Have a question?</span>
        <div className="contact-details-section">
          <a href="tel:9606492202" className="contact-details">
            <img src={Call} />
            <div className="contact-content">
              <span className="contact-name">Call us on Office:</span>
              <span className="contact-number">9606492201</span>
            </div>
          </a>
          <a href="tel:9606492202" className="contact-details">
            <img src={Call} />
            <div className="contact-content">
              <span className="contact-name">Call us on Design/Technical:</span>
              <span className="contact-number">9606492202</span>
            </div>
          </a>

          <a href="mailto:moirabuilds@gmail.com" className="contact-details">
            <img src={Email} />
            <div className="contact-content">
              <span className="contact-name">Drop an email at:</span>
              <span className="contact-number">moirabuilds@gmail.com</span>
            </div>
          </a>
        </div>
      </div>
      <span className="copy-right-text">
        © 2022 Moira Building System, All Rights Reserved
      </span>
    </div>
  );
};

export default Footer;

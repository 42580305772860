import React from "react";
import { Col, Row } from "react-bootstrap";
import Heading from "../../../../Components/Heading";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import Bay1 from "./Assets/bay1.jpg";
import Bay2 from "./Assets/bay2.jpg";
import Bay3 from "./Assets/bay3.jpg";
import Bay4 from "./Assets/bay4.jpg";
import img from "./bay.jpg";
import "./bayWindow.css";

const detail = [
  {
    header: "Bay Window",
    image: img,
  },
];
const BayWindow = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />

      <Row className="bay-section">
        <Col md={12} sm={12}>
          <div className="spacilities-content mb-4">
            As a good architect, you need to have an eye for solutions that are
            modern, technically sound and innovative, and a knack for delivering
            within the given costs and timelines. And when it comes to doors and
            windows,Our uPVC systems offer an optimal balance between
            aesthetics, design, performance and value.
          </div>
          <Heading text={"Here’s a look at our collection of Bay Windows:"} />
          <hr className="divided-line" />
          <div className="combination-images">
            <img src={Bay1} />
            <img src={Bay2} />
            <img src={Bay3} />
            <img src={Bay4} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BayWindow;

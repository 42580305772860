import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../header/Header";
import HeaderNew from "../header/HeaderNew";
import WhatsApp from "../WhatsApp/WhatsApp";
import Footer1 from "../../Footer";
import NewNavbar from "../header/newNavbar";
import BackToTop from "../backToTop/BackToTop";
const DefaultLayout = ({ component: Component }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  // scroll event callback function
  const onScroll = (e) => {
    const window = e.currentTarget;
    if (Math.floor(window.scrollY) > 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      {/* <div className="mobile"> */}
      {/* <NewNavbar /> */}
      {/* <HeaderNew /> */}
      {/* </div> */}
      {/* <div className="desktop pb-2" style={{ width: '100%', zIndex:'100000', position:'fixed' }}> */}
      {/* <Header /> */}
      <NewNavbar scroll={scroll}/>
      {/* </div> */}
      {/* <Header /> */}
      <WhatsApp />
      <Component />
      <BackToTop />
      <Footer1 />
    </div>
  );
};

export default DefaultLayout;

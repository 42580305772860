import React from "react";
import { render } from "react-dom";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
// import "normalize.css/normalize.css";
import "./custom.css";
import "./styles.css";

import img1 from  "./carousel/img/img1.jpg";
import img2 from  "./carousel/img/img2.jpg";
import img3 from  "./carousel/img/img3.jpg";
import img4 from  "./carousel/img/img4.jpg";
import logo from "../../assets/icon/logo.png"
const content = [
  {
    title: "Designed with you in View",
    description:
      "Your Dream Home made Clear, Visionary, Vibrant",
    // button: "Get Started",
    image: img1,
    user: "Luan Gjokaj",
    userProfile: logo,
    classnames: "slide1"
  },
  {
    title: "Creating cozy interior spaces amidst wild eterior landscapes",
    description:
      "Weatherproof technology built into windoes for a superior experience",
    // button: "Get Started",
    image: img2,
    user: "Erich Behrens",
    userProfile: logo,
    classnames: "slide2"
  },
  {
    title: "Like a 4k screen, capable of muting undesired sound out",
    description:
      "Noise Cancellation technology windows for homes, hospitals, offices and schools",
    button: "Get Started",
    image: img3,
    user: "Bruno Vizovskyy",
    userProfile: logo,
    classnames: "slide3"
  },
  {
    title: "Market leader in uPVC profiles in india",
    description:
      "Moira Doors and Windows with a county wide presence, the company provides customised solutions in widnows and doors that are specially disegned to suit the harsh climatic and environmental conditions in India",
    button: "Get Started",
    image: img4,
    user: "Bruno Vizovskyy",
    userProfile: logo,
    classnames: "slide4"
  }
];

export const Carousel = () => (
    <div className="wrapper">
    <Slider className="slider-wrapper1">
      {content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          style={{ background: `url('${item.image}') no-repeat center center` }}
        >
          <div className="inner">
            <h1 className="stokes great-vibes">{item.title}</h1>
            <h4 className="stokes1">{item.description}</h4>
            {/* <button>{item.button}</button> */}
          </div>
          <section>
            <img src={item.userProfile} alt={item.user} />
            <span>
              Powered By <strong>Moira Building Systems</strong>
            </span>
          </section>
        </div>
      ))}
    </Slider>
  </div>
);

render(<Carousel />, document.getElementById("root"));

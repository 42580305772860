import {
  Drawer
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import Sidebar from "./Sidebar";

const styles = (theme) => ({
  list: {
    width: 250,
    top: '65px'
  },
  fullList: {
    width: "auto"
  },
});

const DrawerComponent = (props) => {
  return (
    <Drawer open={props.open} onClose={props.toggleDrawerHandler} anchor={"right"}>
      <Sidebar />
    </Drawer>
  );
};

export default withStyles(styles)(DrawerComponent);

import React from "react";
import { Carousel } from "react-bootstrap";

function Slides(props) {
  let { data, hide } = props;
  return (
    <div className="index-1">
      <Carousel style={{ position: 'relative' }}>
        {data &&
          data.length > 0 &&
          data.map((list) => (
            <Carousel.Item>
              <img
                className="carousel-image"
                src={list.image}
                alt="First slide"
              />
              <Carousel.Caption className="carousel-image-section">
              <h3 className="heading-4">{list.header}</h3>

                {/* {hide ? (
                  <h3 className="heading-4">{list.header}</h3>
                ) : (
                  <h3 className="heading-5 heading-5-text">{list.header}</h3>
                )} */}
                <p>{list.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}

export default Slides;

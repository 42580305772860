import React from "react";
import Card from "react-animated-3d-card";
import img from "./assets/SpeD.jpg";
import img1 from "./assets/SpeM.jpg";
import CssButton from "../../Components/CssButton";
import { Fade } from "react-reveal";
import { Col, Row } from "react-bootstrap";

const Special = () => {
  return (
    <section id="about">
      <div className="about" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="about-body index">
        <Fade left duration={2000}>
          <div className="about-description">
            <h1 className="gradient">SPECIAL</h1>
            <p style={{ color: "#000000" }}>
            Break the monotony of rectangular windows with arched, combination and bay  uPVC Windows from Moira.
              <br />
            </p>
            <Row>
            <Row className="mb-5">
              <Col xs={6}>
              <CssButton label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arch&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" link="/Arched" />
              </Col>
              <Col xs={6}>
              <CssButton label="Lamination" link="/Lamination" />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
              <CssButton label="Combination" link="/Combination" />
              </Col>
              <Col xs={6}>
              <CssButton className="mobile" label="B- Window"  link="/Bay-Window" />
              <CssButton className="desktop" label="&nbsp;&nbsp;B-Window&nbsp;&nbsp;" link="/Bay-Window" />
              </Col>
            </Row>
            </Row>
          </div>
          </Fade>
          <Fade right duration={2000}>
          <div className="desktop">
            <Card
              className="test"
              style={{
                backgroundImage: `url(${img1})`,
                width: "450px",
                height: "300px",
                cursor: "pointer",
              }}
              onClick={() => console.log("Card clicked")}
            />
          </div>
          <div className="mobile">
            <Card
              className="test"
              style={{
                backgroundImage: `url(${img})`,
                width: "300px",
                height: "209px",
                cursor: "pointer",
              }}
              onClick={() => console.log("Card clicked")}
            />
          </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Special;

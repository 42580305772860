import React from "react";
import { Accordion, Container, Nav } from "react-bootstrap";
import Paragraph from "../../../../Components/base/Paragraph";
import Heading from "../../../../Components/Heading";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import img from "./window.jpg";
const detail = [
  {
    header: "Windows",
    image: img,
  },
];

const Windows = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />
      <Container>
        <div className="p-2">
          <Paragraph
            text={
              "Imagine waking up to an image of a beautiful sunrise. With uPVC sliding windows, that can be a reality. With a gentle push, our sliding windows glide open sideways with the help of rollers, offering easy access to the beauty outside.uPVC sliding windows are best suited for apartments, villas and offices. Available in a range of sizes, they can also be fitted with fly mesh depending on individual preferences."
            }
          />

          <h3>Here’s a look at our collection of sliding windows:</h3>
          <Heading text={"Type of Windows"} />
          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>2 Track uPVC Sliding Window</Accordion.Header>
                <Accordion.Body>
                  This quintessential uPVC window revolutionised the window
                  industry in India. MOIRA track uPVC sliding windows are
                  easy to use, functional and cost-effective.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Easy to use</li>
                    <li>Cost efficient</li>
                    <li>Multiple size options</li>
                    <li>Aesthetically pleasing</li>
                    <li>Maintenance free</li>
                    <li>Provides 50% opening at any given point</li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span> 6’Height x 6’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2.5 Track uPVC Sliding Window
                </Accordion.Header>
                <Accordion.Body>
                  This quintessential uPVC window revolutionised the window
                  industry in India. MOIRA track uPVC sliding windows are
                  easy to use, functional and cost-effective.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Easy to use</li>
                    <li>Cost efficient</li>
                    <li>Multiple size options</li>
                    <li>Aesthetically pleasing</li>
                    <li>Maintenance free</li>
                    <li>Provides 50% opening at any given point</li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span>6’ Height x 6’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>3 Track uPVC Sliding Window</Accordion.Header>
                <Accordion.Body>
                  This uPVC sliding window has the same features as the classic
                  2 track sliding window with an added advantage of fly mesh
                  compatibility.
                  <h4 className="fw-bold">Key Features:</h4>
                  <ul className="ps-5">
                    <li>Easy to use</li>
                    <li>Maintenance free</li>
                    <li>Aesthetically pleasing</li>
                    <li>Multiple size options for larger windows</li>
                    <li>Fly mesh compatibility</li>
                    <li>Provides 66% opening at any given point</li>
                  </ul>
                  <span className="fw-bold pt-1">Maximum Size:</span>{" "}
                  <span> 6’ Height x 6’ Width</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Windows;

import React from "react";
import { Card, Container } from "react-bootstrap";
import { Banner } from "../../../Components/base/Banner/Banner";
import img from "./hotels.jpg";
const Hotels = () => {
  return (
    <div>
      <Banner image={img} title={"MOIRA"} description={"Hotels"} />
      <Container>
        <Card>
          <Card.Body>
            For travellers and guests, a hotel is much more than a place to
            spend the night. It is an experience. Be it a resort, a luxury hotel
            or a modest B&B, a hotel offers its guests a place to relax, unwind
            and be pampered. uPVC Windows and doors are perfect for a hotel room
            as they ensure a peaceful environment, while enhancing the overall
            looks of the hotel’s interiors and exteriors.  uPVC Windows are
            optimally designed for the needs of the hotel industry. Not only are
            they aesthetically magnificent and easy to maintain they also
            provide functional benefits that are required in hotel rooms such as
            insulation from sound, dust, pollution and rains. With , large
            wall-to-wall windows are possible with fixed and openable options.
            In addition  uPVC Windows can be custom fitted with speciality
            glasses of varying thickness from 5mm (single glazing) to 24mm
            (double glazing) to improve thermal insulation and reduce the
            air-conditioning requirements.  uPVC Windows by the very nature of
            their production are easy to maintain, in addition the windows can
            be specially designed for access to easy external cleaning reducing
            the need for periodic scaffolding/suspended platforms for cleaning
            windows at higher floors. For security reasons special handles can
            be provided that open with master keys limiting guests access to
            critical areas. In addition to the standard white profiles,
            laminated windows are also available to keep pace with the overall
            design and architecture of the hotel.
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Hotels;

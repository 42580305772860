import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slides from "../../Components/reactCarousel/ReactCarousel";
import img from "./prod.jpg";

const detail = [
  {
    header: "Production",
    image: img,
  },
];
const Production = () => {
  return (
    <div className="text-start">
      <Slides data={detail} />

      <Row className="bay-section">
        <Col md={12} sm={12}>
          <div className="production-header-section mt-4 mb-5">MANUFACTURED TO PERFECTION</div>
          <div className="spacilities-content mb-4">
           Our profiles are manufactured in a state-of-the-art manufacturing
            plant near Hyderabad. The facility is spread over an area of
            1,25,000 sft. with 18 extrusion lines along with lamination and
            arch-bending facilities where more than 200 employees put in their
            knowledge, expertise and hard work to manufacture the finest
            windows.
          </div>

          <div className="spacilities-content mb-4">
            The facility boosts of an R&D and Quality Control Centre manned by
            qualified technicians that ensure the quality of the end product is
            not compromised at any stage. Our profiles produced are lead-free and
            conforming to British Standard BS EN 12608 with tropicalised uPVC
            compound using high quality additives from Europe to suit harsh
            Indian climatic conditions.
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Production;

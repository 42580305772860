import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const withLocation = Component => props => {
  const location = useLocation();

  return <Component {...props} location={location} />;
};
function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  });

  return (null);
}

export default withLocation(ScrollToTop);
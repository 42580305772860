import React from 'react'
import MediaShow from "../../../../Components/base/MediaShow/MediaShow";
import Slides from "../../../../Components/reactCarousel/ReactCarousel";
import img1 from "./assets/sl1.jpg";
import img2 from "./assets/sl2.jpg";
import img3 from "./assets/sl3.jpg";
import img4 from "./assets/sl4.jpg";
import img5 from "./assets/sl5.jpg";
import img6 from "./assets/sl6.jpg";
import img7 from "./assets/sl7.jpg";
import img8 from "./assets/sl8.jpg";
import img9 from "./assets/sl9.jpg";
import img10 from "./assets/sl10.jpg";
import img11 from "./assets/sl11.jpg";
import img12 from "./assets/sl12.jpg";
import img13 from "./assets/sl13.jpg";
import img14 from "./assets/sl14.jpg";
import img15 from "./assets/sl15.jpg";
import img16 from "./assets/sl16.jpg";
import img17 from "./assets/sl17.jpg";
import img18 from "./assets/sl18.jpg";
import img19 from "./assets/sl19.jpg";
import img20 from "./assets/sl20.jpg";
import img21 from "./assets/sl21.jpg";
import img33 from "./assets/sl33.jpg";
import img from "./assets/slide.jpg";
const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img33,
];
const detail = [
  {
    header: " I - 60 SLIDING SERIES",
    image: img,
  },
];

const SlidingSThree = () => {
  return (
    <div>
      <Slides data={detail} />
      <MediaShow data={images} />
    </div>
  )
}

export default SlidingSThree;

import React from "react";
import {
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import logo from "./assets/fullLogo.png";
import "./index.css";
import { socialsData } from "../../data/socialsData";
import { makeStyles } from "@material-ui/core/styles";
import SocialIcons from "../SocialIcons";

const Footer = () => {
  const useStyles = makeStyles((t) => ({
    input: {
      border: `4px solid `,
      // backgroundColor: `${theme.secondary}`,
      // color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
        border: `4px solid`,
      },
    },
    message: {
      border: `4px solid `,
      backgroundColor: ``,
      // color: `${theme.tertiary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 500,
      transition: "border 0.2s ease-in-out",
      "&:focus": {
        border: `4px solid}`,
      },
    },
    label: {
      // backgroundColor: `${theme.secondary}`,
      // color: `${theme.primary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 600,
      fontSize: "0.9rem",
      padding: "0 5px",
      transform: "translate(25px,50%)",
      display: "inline-flex",
    },
    socialIcon: {
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "21px",
      // backgroundColor: theme.primary,
      // color: theme.secondary,
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
        // color: theme.secondary,
        // backgroundColor: theme.tertiary,
      },
    },
    detailsIcon: {
      // backgroundColor: theme.primary,
      // color: theme.secondary,
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "23px",
      transition: "250ms ease-in-out",
      flexShrink: 0,
      "&:hover": {
        transform: "scale(1.1)",
        // color: theme.secondary,
        // backgroundColor: theme.tertiary,
      },
    },
    submitBtn: {
      // backgroundColor: theme.primary,
      // color: theme.secondary,
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.08)",
        // color: theme.secondary,
        // backgroundColor: theme.tertiary,
      },
    },
  }));

  const classes = useStyles();
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-cta pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <div className="cta-text">
                  <h4>Find us</h4>
                  <span>1010 Avenue, kudulu gate, Bangalore</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                {/* <i className="fas fa-phone"></i> */}
                <div className="cta-text">
                  <h4>Call us</h4>
                  {/* <Table className="text-white"> */}
                  <div className="text-white">
                    <table>
                      <tbody>
                        <tr>
                          <td>Office:</td>
                          <td>9606492201</td>
                        </tr>
                        <tr>
                          <td>Design/Technical: </td>
                          <td> 9606492202</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* </Table> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                {/* <i className="far fa-envelope-open"></i> */}
                <div className="cta-text">
                  <h4>Mail us</h4>
                  <a
                    href={`mailto:${"moirabuilds@gmail.com"}`}
                    className="text-none"
                  >
                    <span>moirabuilds@gmail.com</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="/">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </a>
                </div>
                <div className="footer-text">
                  <p>
                    We Provide Quality Doors and Windows which all your expect.
                  </p>
                </div>
                <div className="footer-social-icon">
                  <span>Follow us</span>
                  <div className="socialmedia-icons">
                    {socialsData.twitter && (
                      <a
                        href={socialsData.twitter}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.socialIcon}
                      >
                        <FaTwitter aria-label="Twitter" />
                      </a>
                    )}
                    {socialsData.linkedIn && (
                      <a
                        href={socialsData.linkedIn}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.socialIcon}
                      >
                        <FaLinkedinIn aria-label="LinkedIn" />
                      </a>
                    )}
                    {socialsData.instagram && (
                      <a
                        href={socialsData.instagram}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.socialIcon}
                      >
                        <FaInstagram aria-label="Instagram" />
                      </a>
                    )}
                    {socialsData.youtube && (
                      <a
                        href={socialsData.youtube}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.socialIcon}
                      >
                        <FaYoutube aria-label="YouTube" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul className="text-none">
                  <li>
                    <a   href="/">Home</a>
                  </li>
                  <li>
                    <a href="/OurCompany">Our Company</a>
                  </li>
                  <li>
                    <a href="/Gallery">Gallery</a>
                  </li>
                  <li>
                    <a href="/why-moira">Why Moira</a>
                  </li>
                  <li>
                    <a href="/Contact-Us">Contact Us</a>
                  </li>
                  <li>
                    <a href="/Production">Production</a>
                  </li>
                  <li>
                    <a href="/Accessories">Accessories</a>
                  </li>
                  <li>
                    <a href="/Sliding-Windows">Windows</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div style={{ padding: "1rem" }} className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>&copy; 2022 Moira Building Systems. All Right Reserved </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
              <div className="footer-menu">
                <SocialIcons />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
